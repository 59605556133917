
import { GetInvitesItem, GetOnboardingInvitesItem } from "@/types/responses"
import { PhoneNumberUtil, PhoneNumber } from "google-libphonenumber"
import Vue from "vue"
import { CorrectInvitationRequest, CorrectOnboardingInvitationRequest } from "@/types/requests"
import { RoleEnum, UserTypeEnum, InvitationTypeEnum, roleMap } from "@/types/enums"

/************************
 * VALIDATION OBSERVERS *
 ***********************/

import { required, digits, email, max, regex } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate"
import { GrowthbookFeatureFlags, VerjiGrowthBook } from "@/utilities/growthbook"

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance()
setInteractionMode("aggressive")

const isValidMobileNumber = (mobilenumber: string) => {
	const regExp = /[a-zA-Z]/g;
	if(regExp.test(mobilenumber)) {
		return false
	}
	try {
		const number: PhoneNumber = phoneUtil.parse(mobilenumber)
		return phoneUtil.isValidNumber(number) ? true : false
	} catch (e) {
		try {
			const number: PhoneNumber = phoneUtil.parse(mobilenumber, "NO")
			return phoneUtil.isValidNumber(number) ? true : false
		} catch (e) {
			console.error("Cannot decide if it's a valid phone number. Error:" + JSON.stringify(e))
			return false
		}
	}
}

const isRole = (role: string | null | RoleEnum): role is RoleEnum => {
	return Object.values(RoleEnum).includes(role as RoleEnum)
}

const isUserType = (userType: string | null | UserTypeEnum): userType is UserTypeEnum => {
	return Object.values(UserTypeEnum).includes(userType as UserTypeEnum)
}

extend("userrole", {
	validate: (value) => isRole(value),
	message: "Not a valid user role. ({_value_})",
})

extend("usertype", {
	validate: (value) => isUserType(value),
	message: "Not a valid account type. ({_value_})",
})

extend("digits", {
	...digits,
	message: "{_field_} must be {length} digits. ({_value_})",
})

extend("mobilenumber", {
	validate: (value) => isValidMobileNumber(value),
	message: "Not a valid mobile number. ({_value_})",
})

extend("required", {
	...required,
	message: "{_field_} cannot be emppty",
})

extend("max", {
	...max,
	message: "{_field_} cannot be greater than {length} characters",
})

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not fit with {regex}",
})

extend("email", {
	...email,
	message: "The email is not valid",
})

export default Vue.extend({
	name: "ProvisioningEditDialog",
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	props: {
		editing: {
			type: Boolean,
		},
		item: Object as () => (GetInvitesItem | GetOnboardingInvitesItem),
	},
	data(): {
		newInvitationMessage: string
		newPhoneNumber: string
		newEmail: string
		newRole: string
		newUserType: string

		roles: Array<{text: string, value: string}>
		userTypes: string[]

		loading: boolean

		isDirtyForm: boolean
		isValidForm: boolean

		roleMap: Record<string,string>

		allowOnboardingInvitesFlag: boolean
	} {
		return {
			newInvitationMessage: (this.item as GetInvitesItem)?.invitationMessageFragment ?? "",
			newPhoneNumber: this.item.phoneNumber,
			newEmail: this.item.emailAddress ?? "",
			newRole: (this.item as GetInvitesItem)?.role ?? "",
			newUserType: (this.item as GetInvitesItem)?.userType ?? "",

			roles: Object.keys(RoleEnum).map(ro => {
				const text = roleMap[ro]
				const value = ro
				return { text: text, value: value }
			}),
			userTypes: [...Object.keys(UserTypeEnum)],

			loading: false,

			isDirtyForm: false,
			isValidForm: true,

			roleMap: roleMap,
			allowOnboardingInvitesFlag: false
		}
	},
	computed: {
		saveButtonTxt(): string {
			return this.isDirtyForm ? "RE-INVITE (MODIFIED)" : "RE-INVITE (UNMODIFIED)"
		},
		samePhone(): boolean {
			const same =
			// phone to no phone ?? let's check
			(
				this.item.phoneNumber !== null &&
				this.item.phoneNumber !== "" &&
				typeof this.item.phoneNumber === "string" &&
				this.newPhoneNumber !== null &&
				this.newPhoneNumber !== "" &&
				typeof this.newPhoneNumber === "string" &&
				this.item.phoneNumber === this.newPhoneNumber
			) ||
			(
				(
					this.item.phoneNumber === null ||
					this.item.phoneNumber === ""
				) &&
				(
					this.newPhoneNumber === null ||
					this.newPhoneNumber === ""
				)
			)
			return same
		},
	},
	methods: {
		getTitle(item: GetInvitesItem | GetOnboardingInvitesItem){
			if ((item as GetOnboardingInvitesItem)?.isOnboardingInvite) return

			return item.displayName;
		},
		disabledIfExistingUser(item: GetInvitesItem | GetOnboardingInvitesItem): boolean {
			return (item as GetInvitesItem).invitationType === InvitationTypeEnum.InviteExisting ? true : false
		},
		async getAllowOnboardingInvitesFlag(){
            this.allowOnboardingInvitesFlag = VerjiGrowthBook.getInstance().isOn(GrowthbookFeatureFlags.OnboardingInvites)
		},
		async messUp(): Promise<void> {
			this.$nextTick(async () => {
				// eslint-disable-next-line
				const isValid = await (this.$refs as any).observer.validate()
				// eslint-disable-next-line
				const dirt = (this.$refs as any).observer ? (this.$refs as any).observer.flags.changed : true

				this.isValidForm = isValid
				this.isDirtyForm = dirt
			})
		},
		async save(): Promise<void> {
			if (this.loading) return
			this.loading = true
			try {
				const customerId = this.$vStore.state.context.selectedCustomer?.id
				const itemId = (this.item as GetInvitesItem)?.jobId ?? (this.item as GetOnboardingInvitesItem)?.onboardingId

				if (customerId && itemId) {
					// eslint-disable-next-line
					const isValid = await (this.$refs.observer as any).validate()
					if (!isValid) {
						this.loading = false
						return
					}

					if ((this.item as GetOnboardingInvitesItem)?.isOnboardingInvite){
						// Onboarding invites
						const _item = (this.item as GetOnboardingInvitesItem)
						const request: CorrectOnboardingInvitationRequest = {
							phoneNumber: this.samePhone ? _item?.phoneNumber : this.newPhoneNumber
						}

						const payload = {
							onboardingId: itemId,
							request: request
						}

						await this.$vStore.dispatch("provisioning/correctOnboardingInvitation", payload)
					} else {
						// Invites
						const _item = (this.item as GetInvitesItem)
						const request: CorrectInvitationRequest = {
							jobId: itemId,
							newEmailAddress: _item.emailAddress === this.newEmail ? null : this.newEmail,
							newPhoneNumber: this.samePhone ? null : this.newPhoneNumber,
							hasPhoneNumber: this.samePhone ? false : true,
							newInvitationMessage: _item.invitationMessageFragment === this.newInvitationMessage ? null : this.newInvitationMessage,
							userType: _item.userType === this.newUserType ? null : this.newUserType,
							newTempPassword: this.makeTempPassword(6),
							oldRole: _item.role,
							newRole: _item.role === this.newRole ? null : this.newRole,
						}
						const payload = {
							jobId: itemId,
							request: request,
						}
						await this.$vStore.dispatch("provisioning/correctInvitation", payload)
					}

					requestAnimationFrame(() => {
						//eslint-disable-next-line
						if ((this.$refs as any).observer) {
							// eslint-disable-next-line
							;(this.$refs as any).observer.reset()
						}
					})
					
					await new Promise(resolve => setTimeout(resolve, 3000))
					await this.getAllowOnboardingInvitesFlag();
					await this.$vStore.dispatch("provisioning/updateInvites", { allowOnboardingInvitesFlag: this.allowOnboardingInvitesFlag})
					

					if ((this.item as GetOnboardingInvitesItem)?.isOnboardingInvite) {
						this.$emit("showSnackBar", "Re-invitation sent!")
					} else {
						this.$emit("showSnackBar", "You updated the invitation!")
					}
					this.loading = false
				}
			} catch (e) {
				if ((this.item as GetOnboardingInvitesItem)?.isOnboardingInvite) {
						this.$emit("showSnackBar", "Could not send re-invitation.<br/><br/>Error: " + JSON.stringify(e))
					} else {
						this.$emit("showSnackBar", "Could not update the invitation.<br/><br/>Error: " + JSON.stringify(e))
					}

				this.loading = false
			}
			this.$emit("close", null)
		},
		close(): void {
			if (this.loading)
				return
			this.$emit("close", null)
		},
		cancel(): void {
			if (this.loading)
				return
			this.$emit("cancel", null)
		},
		makeTempPassword(length: number): string {
			let result = ""
			const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
			const charactersLength = characters.length
			for (let i = 0; i < length; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength))
			}
			return result
		},
	},
})
